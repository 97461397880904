import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Component, OnInit } from '@angular/core'
import { DataService } from './data.service'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { Router } from '@angular/router'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { apis } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
    registrationError: string
    changePasswordForm: FormGroup
    oldPass: any
    newPass: any
    conPass: any
    inprogress: boolean = false
    breadCrum: any = [
        {
            link: '/admin/change-password',
            value: 'Change Password'
        }
    ]
    constructor(
        private fb: FormBuilder,
        private dataService: DataService,
        public ui: UIHelpers,
        private router: Router,
        private alert: IAlertService,
        private http: HttpClient
    ) {
        this.changePasswordForm = this.fb.group({
            oldPassword: new FormControl(null, [Validators.required]),
            password: new FormControl(null, [Validators.required]),
            repeatPassword: new FormControl(null, [Validators.required])
        })
    }

    get g() {
        return this.changePasswordForm.controls
    }

    changePassword(data: any) {
        this.inprogress = true
        const baseUrl = apis.baseUrl

        let changeData = {
            oldPassword: this.changePasswordForm.controls.oldPassword.value,
            newPassword: this.changePasswordForm.controls.password.value,
            newPasswordConfirmation: this.changePasswordForm.controls.repeatPassword.value
        }

        // this.oldPass = this.changePasswordForm.controls.oldPassword.value
        // this.newPass = this.changePasswordForm.controls.password.value
        // this.conPass = this.changePasswordForm.controls.repeatPassword.value

        if (data.status === 'INVALID') {
            this.alert.error('Please fill-in valid data in all fields & try again.')
            this.inprogress = false

            return
        } else if (changeData.newPassword !== changeData.newPasswordConfirmation) {
            this.alert.error('New Password and Confirm Password do not match')
            this.inprogress = false
            return
        } else if (changeData.newPassword === changeData.newPasswordConfirmation) {
            if (changeData.newPassword === changeData.oldPassword) {
                this.alert.error('New and Old password cannot be same')
                this.inprogress = false
                return
            }
        }

        this.dataService.changePassword(changeData).subscribe((dta: any) => {
            this.inprogress = false
            if (dta.success == false) {
                this.alert.error('Old password is not match')
                return
            } else {
                this.alert.success('Password change successfully')
                this.changePasswordForm.reset()
                this.changePasswordForm.controls.oldPassword.setErrors(null)
                this.changePasswordForm.controls.password.setErrors(null)
                this.changePasswordForm.controls.repeatPassword.setErrors(null)
                return
            }
            // this.inprogress = false
            // if (resp.success == false) {
            //     this.registrationError = resp.message
            //     if (resp.message === 'Old password not match') {
            //         this.alert.error('Incorrect Old Password')
            //     }
            //     return
            // }
            // this.alert.success('Password Changed Successfully')
        })
    }
}
