<section class="change-pass-section">
    <div class="change-pass-container">
        <h1 class="mb-2 text-center">Change Password</h1>
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword(changePasswordForm)" #f="ngForm">
            <div class="row">
                <div class="col-md-12 cg-input-group pb-1">
                    <input type="password" class="cg" formControlName="oldPassword" placeholder="Old Password">
                    <div *ngIf="ui.checkErrors(g.oldPassword, f)" class="errors">
                        <p *ngIf="g.oldPassword.errors?.required">Old Password is Required</p>
                    </div>
                </div>
                <div class="col-md-12 cg-input-group pb-1">
                    <input type="password" class="cg fadeIn" formControlName="password" placeholder="New Password" >
                    <div *ngIf="ui.checkErrors(g.password, f)" class="errors">
                        <p *ngIf="g.password.errors?.required">New Password is Required</p>
                    </div>
                </div>
                <div class="col-md-12 cg-input-group pb-1">
                    <input type="password" class="cg fadeIn" formControlName="repeatPassword" placeholder="Confirm Password" >
                    <div *ngIf="ui.checkErrors(g.repeatPassword, f)" class="errors">
                        <p *ngIf="g.repeatPassword.errors?.required">Confirm Password is Required</p>
                    </div>
                </div>
                <div class="col-md-12 text-center">
                    <button type="submit" class="cg primary" [class.in-progress]="inprogress">
                        Change Password
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</section>